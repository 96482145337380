@import "resources.scss";
@keyframes spin {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: -126px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tcp-spinner {
  display: block;
  text-align: center;
  display: flex;

  &-svg {
    width: 20px;
    height: 20px;
    animation: rotate 1.4s ease-in-out infinite;
    margin-right: 16px;
  }

  &-circle {
    animation: spin 1.4s ease-in-out infinite;
    stroke: $blue;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
  }

  &.fill--grey {
    .tcp-spinner-circle {
      stroke: $light-gray;
    }
  }

  &.fill--black {
    .tcp-spinner-circle {
      stroke: $black;
    }
  }

  .size--md & {
    width: 18px;
    height: 18px;
  }

  @include media-breakpoint-up(sm) {
    &-svg {
      width: 40px;
      height: 40px;

      .size--lg & {
        width: 24px;
        height: 24px;
      }

      .size--md & {
        width: 18px;
        height: 18px;
      }

      .size--sm & {
        width: 12px;
        height: 12px;
      }
    }
  }
}
