@import "resources.scss";
.button {
    @include tn(background color border-color, .3s .3s .3s, 0s 0s 0s, ease-in-out ease-in-out ease-in-out);
    @include body;
    color: #fff;
    cursor: pointer;
    background: $black;
    border: 1px solid $black;
    border-radius: 4px;
    display: flex;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    align-items: center;
    min-width: 160px;
    padding: 0 20px;
    white-space: nowrap;

    &:hover {
        background: black;
        border: 1px solid black;
        color: #fff;
        text-decoration: none;

        .icon-arrow_forward {
            transform: translateX(5px);
        }

        .icon-arrow_downward {
            transform: translateY(3px);
        }

        .icon-arrow_upward {
            transform: translateY(-3px);
        }
    }

    &:disabled {
        background: $gray;
        border-color: $gray;
        cursor: auto;
        opacity: 1;

        .icon-arrow_forward {
            transform: translateX(0);
        }
    }

    i {
        @include tn(transform, 200ms);

        position: relative;
        margin-left: 8px;
        font-size: 14px;

        &.is--first {
            margin-left: 0;
            margin-right: 8px;
        }
    }

    svg {
        margin-right:8px;
        width: 16px;
        height: 16px;
        
        path {
            fill: $dark-gray;
        }
    }

    span {
        line-height: 14px;
        display: flex;
        align-items: center;
    }

    &-light {
        border: 1px solid $black;
        background: $black100;
        color: $black;

        &:hover {
            background-color: $black200;
            color: $black;
        }
    }

    &-large {
      height: 40px;
      font-size: 15px;
    }

    &.is--large {
      @include subtitle2;
      height: $new-input-height;
      width: 100%;
      text-transform: unset;
    }

    &.is--medium {
      height: 40px;
      text-transform: unset;
    }

    @include media-breakpoint-up(sm) {
        @include caption;
        min-width: auto;
        height: 36px;

        &.is--medium {
            @include body;
        } 
    }
}
