@import "resources.scss";
.tcp-legal-notice {
  ul {
    padding-left: 17px;
    li {
      list-style: disc;

      &::marker {
        font-size: 10px;
      }
    }
  }

  &-terms {
    position: relative;
    padding: 12px 16px;
    background: $grey100;
    margin-bottom: 16px;
  }
}
