@import "resources.scss";
.checkout-sidebar-confirm-body {
  &-title {
    @include subtitle1;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 16px;
  }

  button {
    margin-bottom: 24px;
  }
}
