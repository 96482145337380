@import "resources.scss";
.lp-booked-info {
  display: flex;
  flex: 1;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid $border-gray;
  background-color: white;

  &-label {
    @include caption;
    margin-bottom: 4px;
  }

  &-value {
    font-weight: 500;
  }

  &-value + &-value {
    margin-top: 4px;
  }

  &-ticket {
    margin-bottom: 4px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    color: $blue;

    i {
      font-size: 16px;
      vertical-align: middle;
    }
  }

  &-item:not(&-item:last-child) {
    margin-bottom: 15px;
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    padding: 24px;

    &-item {
      @include make-col(3 ,12);
    }

    &-item:not(&-item:last-child) {
      margin-bottom: 0;
    }

    &-item:not(&-item:first-child) {
      border-left: 1px solid $border-gray;
      padding: 0 16px;
    }
  }
}
