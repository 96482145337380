@import "resources.scss";
.tcp-trip {
  @include make-box;
  display: flex;
  flex-direction: column;

  &-inner {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    cursor: pointer;
  }

  &-infos {
    padding: 16px;
    position: relative;
    flex: 1;
  }

  &-provider {
    margin-left: 16px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 23px;
    flex-shrink: 0;
    max-width: 28px;

    img {
      max-width: 100%;
    }
  }

  &-top {
    display: flex;
    flex: 1;
  }

  &-time {
  }

  &-select {
    &-not-available {
      color: $red;
    }
  }


  &-location {
    @include caption;
    display: flex;
    justify-content: space-between;
    width: 100%;

    span {
      max-width: 45%;

      &:last-child {
        text-align: right;
      }
    }
  }

  &-footer {
    display: flex;
  }

  @include media-breakpoint-down(sm) {
    @media screen {
      &-footer {
        flex-basis: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px 16px;
        margin-left: 44px;
      }

      &-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid $grey200;
        padding: 4px 0;
        flex-basis: 100%;

        i {
          font-size: 24px;
        }
      }

      &-is-open-details {
        display: block;
        position: fixed;
        height: 100vh;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 100vw;
        overflow: scroll;
        background: white;
        z-index: 400;
        padding-top: $header-height-mobile;
        cursor: default;

        .tcp-trip {
          &-inner {
            border-bottom: 1px solid $grey200;
          }
        }

        .is--demo & {
          top: $banner-height;
        }
      }
    }

  }


  @include media-breakpoint-up(sm) {
    &-inner {
      border-bottom: 0;
      padding: 0 24px;
      justify-content: flex-end;
      cursor: default;
      flex-wrap: nowrap;
    }

    &-provider, &-infos, &-time, &-select {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    &-infos {
      margin-right: 24px;
      padding-left: 0;
      padding-right: 0;
    }

    &-time {
      padding-left: 16px;
      padding-right: 24px;
    }

    &-provider {
      margin-left: 0;
      margin-right: 24px;
      margin-top: 0;
      align-items: center;
      max-width: 40px;
    }

    &-select {
      padding-left: 28px;
      padding-right: 0;
      text-align: right;
      display: flex;
      flex-direction: column;
      border-left: 1px solid $grey200;

      .tcp-tooltip {
        display: block;
      }
      
      .tcp-tooltip-header {
        padding-bottom: 0;
      }

      .button {
        margin-top: auto;
        margin-left: auto;
        min-width: 150px;
        margin-top: 14px;
      }
    }
  }
}
