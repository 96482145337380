@import "resources.scss";
.tcp-error-tooltip {
  @include caption;

  position: absolute;
  padding: 5px 8px 7px;
  z-index: 1;
  background: $dark-gray;
  border-radius: 4px;
  left: 25px;
  transform: translateX(-30%);
  top: calc(100% - 4px);
  text-align: center;
  width: auto;
  color: white;

  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: auto;
    border: 4px solid transparent;
    border-top: 0;
    z-index: 2;
    top: -4px;
    left: calc(30% - 5px);
    border-bottom-color: $dark-gray;
  }
}
