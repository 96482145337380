//  ================================================
//  Typography
//  ================================================

@mixin font-small {
  font-size: 10px;
  line-height: 12px;
}

@mixin font-medium {
  font-size: 13px;
  line-height: 16px;

  @include media-breakpoint-up(sm) {
    font-size: 12px;
    line-height: 17px;
  }
}

@mixin font-large {
  font-size: 15px;
  line-height: 20px;

  @include media-breakpoint-up(sm) {
    font-size: 14px;
    line-height: 20px;
  }
}

@mixin font-extralarge {
  font-size: 18px;

  @include media-breakpoint-up(sm) {
    font-size: 15px;
  }
}

@mixin headline {
  @include font-large;

  font-weight: bold;
  line-height: 25px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

@mixin f-h2 {
  font-size: 24px;
  line-height: 30p;
}

@mixin f-subtitle1 {
  font-size: 18px;
  line-height: 28p;
}

%is-underlined {
  display: inline-block;
  border-bottom: 1px solid $body;
  padding-bottom: 2px;
}

%is-bold {
  font-weight: bold;
}

%is-normal {
  font-weight: normal;
}

%is-font-small {
  @include font-small;
}

%is-font-medium {
  @include font-medium;
}

%is-font-extralarge {
  @include font-extralarge;
}

%is-font-medium-strict {
  font-size: 14px;
}

%is-font-small-strict {
  font-size: 12px;
}

%is-font-large {
  @include font-large;
}

// new font styles
@mixin body {
  line-height: 20px;
  font-size: 14px;
}

@mixin headline3 {
  font-size: 24px;
  line-height: 34p;
  letter-spacing: -0.5px;
  font-weight: bold;
}

@mixin caption {
  font-size: 12px;
  line-height: 16px;
}

@mixin label {
  font-size: 10px;
  line-height: 14px;
}

@mixin subtitle1 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}

@mixin subtitle2 {
  font-size: 16px;
  line-height: 24px;
}

@mixin text {
  font-size: 14px;
  line-height: 20px;
}
