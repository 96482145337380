@import "resources.scss";
.tcp-link {
  cursor: pointer;
  font-weight: 500;
  color: $blue;
  height: 40px;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    margin-right: 8px;
    fill: $blue;
  }

  .icon-arrow_forward:last-child {
    @include tn(transform, 200ms);
    color: $blue;
    font-size: 16px;
    margin-left: 6px;
  }
  
  i:first-child {
    margin-right: 8px;
  }

  i:last-child {
    margin-left: 8px;
  }

  &.is--medium {
    @include caption;
  }

  &.is--small {
    @include caption;
    height: 28px;
  }

  &.is--disabled {
    pointer-events: none;

    &, i, i:last-child {
      color: $grey400;
    }

    svg path {
      fill: $grey400;
    }
  }

  &:hover {
    color: $blue900;

    .icon-arrow_forward {
      transform: translateX(5px);
    }
  }
}
