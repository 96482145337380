@import "resources.scss";
.multi-cost-centers-form-fields {
  margin-bottom: 8px;

  &-row {
    display: flex;
  }
  .button {
    margin-top: 24px;
  }
  &-row + &-row {
    margin-top: 16px;
  }

  &-delete {
    padding: 8px;
    margin-left: 16px;
    flex-grow: 0;

    i {
      font-size: 20px;
    }
  }

  &-select {
    flex-grow: 1;
  }

  .is--percentage {
    position: relative;
    margin-top: 8px;

    input {
      padding-left: 35px;
      font-size: 14px;
      display: block;
      width: 100%;
      height: 38px;
      border-radius: $border-radius !important;
      background-color: #fff;
      border: 1px solid $light-gray;
      background-color: #fff;
      background-image: none;
      background-clip: padding-box;

      &:focus {
        outline: none;
      }
    }

    .Select-input input {
      border: 0;
      padding: inherit;
      display: inline-block;
    }

    &:before {
      content: '%';
      position: absolute;
      line-height: 36px;
      background: $lighter-gray;
      width: 25px;
      text-align: center;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      left: 1px;
      top: 1px;
      bottom: 1px;
    }
  }
}
