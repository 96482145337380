@import "resources.scss";
.lp-booking-info-item {
  padding: 20px 24px;
  border-top: 1px solid $grey200;

  &-title {
    @include subtitle2;
    font-weight: 600;
    margin-bottom: 16px;
    width: 100%;
  }

  &-details {
    position: relative;
    width: 100%;
  }

  &-location {
    font-weight: 500;
    margin-bottom: 4px;
  }

  &-date {
    @include caption;
    color: $black600;
    
  }

  &-route {
    padding-left: 32px;
    position: relative;
    margin-bottom: 20px;

    i,
    svg {
      font-size: 20px;
      width: 20px;
      height: 24px;
      color: $grey500;
      position: absolute;
      left: 0;

      path {
        fill: $black400;
      }
    }

    &.is--dep::before {
      content: '';
      display: block;
      width: 1px;
      background: $black400;
      position: absolute;
      top: 25px;
      bottom: -17px;
      left: 9px;
    }

    &.is--arr::before {
      content: '';
      display: block;
      width: 9px;
      border-radius: 50%;
      height: 9px;
      background: $black400;
      position: absolute;
      left: 5px;
      top: 5px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-link {
    color: $blue;
    i {
      @extend %is-font-medium;
      margin-right: 5px;
    }

    &-disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &-additional {
    padding-top: 16px;
    font-weight: 500;

    .icon-seating {
      color: $grey500;
      font-size: 20px;
      margin-right: 8px;
    }

    svg {
      font-size: 20px;
      width: 20px;
      height: 24px;
      margin-right: 7px;

      path {
        fill: $grey500;
      }
    }

    & > div + div {
      margin-top: 20px;
    }
  }

  &-sub_title {
    display: flex;
    align-items: center;
    white-space: pre-wrap;
  }

  &-label {
    @include caption;
    color: $grey500;
    font-weight: 400;
    white-space: nowrap;
    padding-left: 28px;
  }

  @include media-breakpoint-up(sm) {
    &-inner {
      display: flex;
    }

    &-details {
      flex-basis: 50%;
      flex-grow: 1;
    }

    &-additional {
      flex-basis: 50%;
      padding-top: 0;
    }
  }
}
