@import "resources.scss";
.lp-booking-info {
  @include make-box;
  width: 100%;

  &-title {
    @include subtitle2;
    font-weight: bold;
    padding: 18px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex-wrap: wrap;
    gap: 16px;

    .alert {
      margin-top: 16px;
    }
  }

  &-section {
    padding-bottom: 20px;
  }

  &-sub-total {
    text-align: right;
    padding: 16px 16px;
    background: $gray-background;
    border: 1px solid $border-gray;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  &-list_item {
    margin-bottom: 10px;
  }

  .lp-checkout-price {
    margin: 0 24px;
  }

  @include media-breakpoint-up(sm) {
    &-wrapper {
      flex-direction: row;
    }
  }
}
