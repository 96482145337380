@import "resources.scss";
.alert {
  @include caption;
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 12px;
  text-align: center;
  font-weight: 400;
  width: 100%;

  &-warning {
    border-color: $yellow;
    background-color: $yellow10;
  }
}
