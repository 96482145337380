//  ================================================
//  Checkout
//  ================================================

.checkout {
  .list {
    &-badge {
      margin-left: 20px;
    }

    .route-tickets {
      text-align: right;
      font-size: 12px;
      line-height: 18px;
      opacity: 0.7;
    }

    @include media-breakpoint-up(sm) {
      &-select {
        strong {
          font-size: 18px;
        }
      }
    }
  }

  &-title {
    @include subtitle2;

    padding: 24px;
    font-weight: 500;
    margin-bottom: 0;
  }

  &-info {
    text-align: left;
  }

  &-total {
    display: inline-block;
  }

  &-submit-btn {
    margin: 12px 0;
  }

  &-submit-btn-full {
    margin: 12px 0;
    display: inline-block;
    width: 100%;

    button {
      float: right;
    }
  }

  &-complted-trip-btn {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  &-box {
    h2 {
      border-bottom: 1px solid $border-gray;
    }

    &-content {
      padding: 20px 24px;
    }
  }

  &-passengers {
    margin-bottom: 20px;

    &-item {
      display: flex;
      align-items: center;

      i {
        margin-right: 12px;
        color: $grey400;
        font-size: 20px;
      }

      label {
        @include caption;
        margin-bottom: 2px;
        color: $black600;
      }

      strong {
        display: block;
      }
    }

    &-note {
      color: $black600;
    }
  }

  &-form {
    padding: 20px 24px;
    border-bottom: 1px solid $border-gray;

    &-row {
      display: flex;
      gap: 24px;

      .textfield {
        max-width: 50%;
        width: 100%;
      }
    }

    &-flex {
      display: flex;
      align-items: center
    }

    &-label {
      @include subtitle2;
      font-weight: 500;
      margin-bottom: 24px;
      display: block;
    }

    &-supporting {
      @include caption;
      color: $black600;
      font-weight: 400;
    }

  }

  &-hotel-notes {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: $gray-background;
    margin-top: -12px;
    margin-bottom: 20px;
    border: 1px solid $border-gray;
    border-top: 0;

    &-label {
      margin-bottom: 5px;
      font-weight: bold;
      flex-shrink: 1;
    }

    &-field {
      flex: 1;
      max-width: 0;

      //TODO: refactor with FormGroup
      textarea {
        font-family: $base-font;
        height: auto;
        padding: 10px;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    &-hotel-notes {
      flex-direction: row;
      margin-top: -12px;

      &-label {
        width: 200px;
        margin-right: 16px;
      }
    }

    &-passengers-item {
      justify-content: flex-start;
    }
  }
}

.icon-info__alignment-checkout {
  vertical-align: inherit;
  padding-right: 0.25rem;
}
