@import "resources.scss";
.loader-results {
    margin-bottom: 20px;
    
    &-inner {
        padding: 0 20px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        @include media-breakpoint-up(sm) {
            padding: 0;
        }
    }
}
