@import "resources.scss";
.loader-hotel-details {
  &-progress {
    background-color: $light-gray;
  }
  &-inner {
    padding: 25px 30px 30px 20px;
    display: flex;
  }
}
