@import "resources.scss";
.checkout-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  padding: 16px;
  background-color: white;
  border-top: 1px solid $black200;

  &-total {
    margin-bottom: 16px;
    display: flex;
    align-items: flex-end;
  }

  &-price {
    @include subtitle1;
    font-weight: 700;
    margin-left: 8px;
  }

  .button {
    width: 100%;
    margin-left: auto;
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
    left: $sidebar-width;
    
    &-total {
      margin-bottom: 0;
    }

    .button {
      width: auto;
    }
  }
}
