@import "resources.scss";
.sidebar-route {
  padding: 8px 0 4px;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }

  img {
    max-width: 20px;
    max-height: 20px;
  }

  svg,
  img {
    margin-right: 8px;
    flex-shrink: 0;
  }
}
