@import "resources.scss";
.tcp-checkout-timer {
    display: flex;
    background-color: $blue900;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    padding: 18px 24px;
    margin-left: -16px;
    margin-right: -16px;
    color: white;

    &-clock {
        font-weight: 500;
    }

    @include media-breakpoint-up(md) {
        margin: 0;
    }
}
