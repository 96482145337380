@import "resources.scss";
.textfield {
  border-radius: 4px;
  position: relative;
  margin-bottom: 12px;
  background: white;
  z-index: 0;

  &-input {
    @include tn(border-color);

    display: flex;
    align-items: center;
    position: relative;
    border-radius: 4px;
    border: 1px solid $grey500;
    overflow: hidden;

    input {
      height: $new-input-height;
      padding: 27px 12px 9px;
      width: 100%;
      background: transparent;
      z-index: 1;
      text-overflow: ellipsis;
    }

    &:hover {
      border-color: $dark-gray;
    }
  }

  &-label {
    padding: 0;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
    position: absolute;
    left: 0px;
    top: 0px;
    transform: translate(14px, 18px) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
    background: white;
    color: $black600;
  }

  &-error {
    @include caption;
    transition: opacity 500ms cubic-bezier(0, 0, 0.2, 1), visibility 50ms cubic-bezier(0, 0, 0.2, 1);
    text-align: left;
    margin: 3px 0 0;
    color: $red;
    position: relative;
    opacity: 0;
    visibility: hidden;
  }

  &.is--active {
    .textfield {
      &-label {
        transform: translate(13px, 8px) scale(0.85);
        max-width: calc(133% - 32px);
        pointer-events: auto;
        user-select: none;
        color: $dark-gray;
      }
    }
    &.has--icon {
      .textfield {
        &-label {
          transform: translate(16px, 8px) scale(0.85);
        }
      }
    }
  }

  &.has--focus {
    .textfield-input {
      border-color: $dark-gray;
    }
  }

  &.has--icon {
    .textfield {
      &-input input {
        padding-left: 44px;
      }
      &-label {
        left: 29px;
      }
      &-icon {
        @include pos(center left 14px);
        display: inline-flex;
        svg {
          width: 20px;
        }
      }
    }
  }

  &.has--trailingicon {
    .textfield {
      &-input input {
        padding-right: 50px;
      }
      &-trailingicon {
        @include pos(center right 0);
        display: inline-flex;
        z-index: 0;
        padding: 14px;

        svg {
          width: 20px;
          fill: $grey700;
        }

        &.has--cursor {
          cursor: pointer;
          z-index: 1;
        }
      }
    }
  }

  &.has--error {
    .textfield {
      &-input {
        border-color: $red;
      }

      &-label {
        color: $red;
      }

      &-error {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &.no--label {
    .textfield {
      &-input input {
        height: 36px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  &.no--onchange {
    .textfield {
      &-input input {
        caret-color: transparent;
        cursor: pointer;
      }
    }
  }

  &.is--disabled {
    .textfield {
      &-input {
        border-color: $grey300;
        input {
          color: $grey500;
        }
      }

      &-label {
        color: $grey500;
      }

      &-icon svg path {
        fill: $grey500;
      }
    }
  }

  &.size--small {
    input {
      padding: 8px;
    }
  }
}
