@import "resources.scss";
.checkout-booking-info {
  @include caption;
  background: white;
  display: flex;
  padding: 16px 24px 0;
  flex-wrap: wrap;
  width: 100%;

  div {
    padding-right: 16px;
    flex-basis: 50%;
    max-width: 50%;
    margin-bottom: 16px;

    strong {
      @include body;
      display: block;
      font-weight: 500;
      margin-top: 2px;
    }

    &:nth-child(2n) {
      padding-left: 16px;
      border-left: 1px solid $black200;
    }
  }

  @include media-breakpoint-up(sm) {
    flex-wrap: nowrap;

    div {
      max-width: none;
      flex-basis: auto;
    }

    div + div {
      padding-left: 16px;
      border-left: 1px solid $black200;
    }
  }
}
