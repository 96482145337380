@import "resources.scss";
.lp-checkout-price {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  background: $black100;
  border-radius: 8px;
  margin-bottom: 20px;

  &-number {
    font-weight: 500;
  }

  &-inner {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    width: 100%;
  }

  &.is--total {
    @include subtitle2;
    margin-bottom: 0;
    border: 1px solid $black200;
    flex-wrap: wrap;
    margin-bottom: 16px;
    background: $black200;
    overflow: hidden;

    .lp-checkout-price-inner {
      padding: 16px 16px;
    }

    .lp-checkout-price-label {
      font-weight: 500;
    }
  }

  &.is--subtotal {
    background: $black200;
  }

  @include media-breakpoint-up(sm) {
    margin: 15px 0px 20px;

    &.is--subtotal,
    .is--total {
      .lp-checkout-price-inner {
        padding: 16px 24px;
      }
    }
  }
}
