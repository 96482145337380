@import "resources.scss";
.tcp-hotel {
    @include make-box;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;

    &-inner {
        display: flex;
        position: relative;
        flex-wrap: wrap;
    }

    &-image {
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 220px;
        background: $gray-background url(../../../assets/img/backgrounds/hotel_placeholder.svg) no-repeat center / 75px;
    }

    &-info {
        padding: 15px 20px 0;
        width: 100%;
    }

    &-footer {
        text-align: center;
        display: flex;
        align-items: center;
    }

    &-airbnb {
      margin-right: 15px;
      background: $red;
      border-radius: 4px;
      color: white;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include media-breakpoint-down(sm) {
      &-footer {
        border-top: 1px solid $border-gray;
        margin: 0 -20px;
        padding: 10px 20px;

        .tcp-label-button {
          margin-right: auto;
          span {
            display: none;
          }
        }
      }

      &-is-open-details {
        display: block;
        position: fixed;
        height: 100vh;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 100vw;
        overflow: scroll;
        background: white;
        z-index: 200;
        cursor: default;

        .tcp-hotel {
          &-image {
            display: none;
          }

          &-inner {
            padding-top: 60px;
          }

          &-footer-price {
            display: none;
          }
        }
      }
    }

    @include media-breakpoint-up(sm) {
        cursor: default;

        &-rental {
          .tcp-hotel-inner {
            height: auto;
            min-height: 135px;
          }
        }

        &-inner {
            height: 135px;
            padding-top: 0;
        }

        &-image {
          @include make-percent-col(25);
          height: auto;
          max-height: 100%;
          background-size: 50px;
        }

        &-info {
            flex: 1;
            padding: 15px 20px 15px 15px;
        }

        &-select {
            padding: 15px 20px 15px 28px;
            border-left: 1px solid $border-gray;
            display: flex;
            flex-direction: column;
            flex-grow: 0;
            align-items: flex-end;

            &-price {
                padding-bottom: 20px;
                text-align: right;
            }

            &-not-available {
                color: $red;
            }

            .button {
                min-width: 150px;
                margin-top: auto;
            }
        }

        &-footer {
          justify-content: space-between;
        }
    }
}
